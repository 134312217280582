exports.linkResolver = function linkResolver(doc) {
  // Route for job posts
  if (doc.type === "job") {
    return "/job/" + doc.lang + "/" + doc.uid
  }
  if (doc.type === "jobs") {
    return "/job/" + doc.uid
  }
  // Homepage route fallback
  return "/"
}

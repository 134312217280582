module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Tea Rock Job Agency","short_name":"Tea Rock","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/CupGreenBg.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"3bba14750074aa3b0c5228e4cb6834df"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"tearock","defaultLang":"fr-ca","langs":["fr-ca","en-ca"],"pages":[{"type":"job","match":"/job/:lang/:uid","path":"/job-preview","component":"/opt/build/repo/src/templates/job-details.js"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
